import React from "react"

import remoteAssetUrl from '../../lib/remote-asset-url'

import TextHeader from '../text-header'

import styles from './component.module.less'

export default (props) => (
    <>
      {props.image &&
        <img className={styles.img} src={remoteAssetUrl(props.image.fluid.src)} alt={props.image.alt} />
      }

      <TextHeader
          className={`${styles.imgHeader}${props.className ? ` ${props.className}` : ''}`}
          containerClassName={`${styles.container}${props.containerClassName ? ` ${props.containerClassName}` : ''}`}
          textClassName={props.textClassName}
          center={props.centerText === undefined || props.centerText === true}
          header={true}
          title={props.title}
          headline={props.headline}
          headlineType="h1"
      >
        {props.children}
      </TextHeader>
    </>
)
