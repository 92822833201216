import React, {Component} from "react"
import {graphql, StaticQuery} from "gatsby"

import remoteAssetUrl from '../../lib/remote-asset-url'

import ContentContainer from '../../components/content-container'
import Button from '../../components/button'
import Text from '../../components/text'

import styles from "./component.module.less"

export default class extends Component {

  locale;

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';

    this.state = {
      play: false
    };

    this.onContinueClick = this.onContinueClick.bind(this);
    this.onPlayClick = this.onPlayClick.bind(this);
    this.onStopClick = this.onStopClick.bind(this);
  }

  onContinueClick() {
    if (!this.props.pageContentRef || !this.props.pageContentRef.current) {
      return;
    }

    this.props.pageContentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  onPlayClick() {
    this.setState(() => ({
      play: true
    }));
  }

  onStopClick() {
    this.setState(() => ({
      play: false
    }));
  }

  render() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allDatoCmsTemplateTexte {
                        nodes {
                            locale
                            videoPlayButtonLabel
                            videoStopButtonLabel
                            pagerNextLabel
                        }
                    }
                }
            `}
            render={data => {
              const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === this.locale);
              return (
                  <section
                      className={`${styles.video}${this.props.className ? ' ' + this.props.className : ''}${this.state.play ? ' ' + styles.playing : ''}`}
                      style={{
                        backgroundImage: 'url(' + remoteAssetUrl(this.props.posterSrc) + ')'
                      }}
                  >

                    {this.state.play &&
                        <ContentContainer className={styles.controls}>
                          <Button className={styles.stop} theme="block" onClick={this.onStopClick}>
                            {templateStrings.videoStopButtonLabel}
                          </Button>
                        </ContentContainer>
                    }

                    {this.state.play &&
                        <>
                          {/* eslint-disable jsx-a11y/media-has-caption */}
                          <video
                              controls={true}
                              autoPlay={true}
                              poster={remoteAssetUrl(this.props.posterSrc)}
                              data-setup="{}"
                          >
                            <source src={remoteAssetUrl(this.props.videoSrc)} type="video/mp4" />
                          </video>
                          {/* eslint-enable jsx-a11y/media-has-caption */}
                        </>
                    }

                    <div className={styles.overlay}>
                      <Text
                          header={true}
                          dark={true}
                          className={styles.txt}
                          center={true}
                          title={this.props.title}
                          headline={this.props.headline}
                          headlineType="h1"
                      />

                      <button className={styles.play} onClick={this.onPlayClick}>{templateStrings.videoPlayButtonLabel}</button>

                      {this.props.pageContentRef &&
                          <button className={styles.continue} onClick={this.onContinueClick}>{templateStrings.pagerNextLabel}</button>
                      }
                    </div>

                  </section>
              );
            }}
        />
    );
  }
}
