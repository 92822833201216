import React from 'react'

import ImageHeader from '../../content-elements/image-header'
import TextHeader from '../../content-elements/text-header'
import VideoHeader from "../../content-elements/video-header";

export default (props) => {
  if (!props.data || props.data.length === 0) {
    return null;
  }

  switch (props.data[0].internal.type) {
    case 'DatoCmsTextHeader':
      return (
          <TextHeader
              center={true}
              locale={props.locale}
              title={props.data[0].title}
              headline={props.data[0].headline}
              pageContentRef={props.pageContentRef}
          >
            {props.data[0].text}
          </TextHeader>
      );
    case 'DatoCmsImageHeader':
      return (
          <ImageHeader
              center={true}
              locale={props.locale}
              title={props.data[0].title}
              headline={props.data[0].headline}
              image={props.data[0].image}
              pageContentRef={props.pageContentRef}
          >
            {props.data[0].text}
          </ImageHeader>
      );
    case 'DatoCmsVideoHeader':
      return (
          <VideoHeader
              locale={props.locale}
              videoSrc={props.data[0].src.url}
              posterSrc={props.data[0].preview ? props.data[0].preview.fluid.src : null}
              title={props.data[0].title}
              headline={props.data[0].headline}
              pageContentRef={props.pageContentRef}
          />
      );
    default:
      return props.children;
  }
}
